import { navigateInternal } from 'UI/lib';
import { AUTH_ERROR_CODE_QUERY_PARAM, AUTH_ERROR_REASON_QUERY_PARAM } from './constants';
import getIsRestrictArea from './getIsRestrictArea';

export const getAuthCallbackError = async () => {
  const currentUrl = new URL(window.location.href);

  if (getIsRestrictArea(currentUrl)) {
    return;
  }

  const externalError = currentUrl.searchParams.get('error');
  const externalErrorDescription = currentUrl.searchParams.get('error_description');
  const isExternalError = Boolean(externalError && externalErrorDescription);

  if (isExternalError) {
    navigateInternal(
      `/?${AUTH_ERROR_CODE_QUERY_PARAM}=422&${AUTH_ERROR_REASON_QUERY_PARAM}=${externalErrorDescription}`
    );
    return;
  }

  const errorStatus = currentUrl.searchParams.get(AUTH_ERROR_CODE_QUERY_PARAM);
  const reason = currentUrl.searchParams.get(AUTH_ERROR_REASON_QUERY_PARAM);

  const isError = Boolean(errorStatus?.trim());

  if (isError) {
    return {
      status: errorStatus,
      reason: reason?.trim(),
    };
  }
};
