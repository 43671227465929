import { Helmet } from 'react-helmet';

const BaseHelmet = () => {
  const cssUrl = `${process.env.CDN_URL}/assets/css`;
  const cssEnv = process.env.ENVIRONMENT?.toLowerCase()?.includes('prod') ? 'prod' : 'staging';

  return (
    <Helmet>
      <link rel="stylesheet" href={`${cssUrl}/materialIcons_${cssEnv}.css`} />
      <link rel="stylesheet" href={`${cssUrl}/fonts_${cssEnv}.css`} />
    </Helmet>
  );
};

export default BaseHelmet;
