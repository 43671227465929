const useConfig = () => {
  const { clientConfig = {} } = window;

  if (!clientConfig.cdnUrl) {
    clientConfig.cdnUrl = process.env.CDN_URL;
  }

  return clientConfig;
};

export default useConfig;
