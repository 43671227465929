import { navigateInternal } from 'UI/lib';
import getIsRestrictArea from './getIsRestrictArea.js';

const ORGANIZATION_TOKEN_QUERY_PARAM = 'token';
const ORGANIZATION_TOKEN_SHORTCUT_PARAM = '/x/';
const ONBOARDING_PATH = '/onboarding';

const checkOrganizationToken = async () => {
  const currentUrl = new URL(window.location.href);

  if (getIsRestrictArea(currentUrl)) {
    return;
  }

  const originalSearchParams = Array.from(currentUrl.searchParams.entries()).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value,
    }),
    {}
  );

  const [, organizationToken] = currentUrl.pathname.split(ORGANIZATION_TOKEN_SHORTCUT_PARAM);

  if (!organizationToken?.length) {
    return;
  }

  const [token] = organizationToken.split('/');

  if (typeof token !== 'string' || !token?.trim()) {
    return;
  }

  const newUrl = new URL(ONBOARDING_PATH, currentUrl.origin);

  delete originalSearchParams[ORGANIZATION_TOKEN_QUERY_PARAM];

  newUrl.searchParams.set(ORGANIZATION_TOKEN_QUERY_PARAM, token);

  Object.entries(originalSearchParams).forEach(([key, value]) => {
    newUrl.searchParams.set(key, value);
  });

  navigateInternal(`${newUrl.pathname}${newUrl.search}`);
};

export default checkOrganizationToken;
