import { getIsInternalUrl } from './getUrlType';

export const navigateInternal = url => {
  if (!getIsInternalUrl(url)) {
    throw new Error(
      `@people-analytix/util.navigateInternal - Cannot navigate to a non-internal url${url}`
    );
  }

  window.location.href = url;
};
