import { clientLogger, navigateInternal } from 'UI/lib';
import { AUTH_ERROR_CODE_QUERY_PARAM, AUTH_ERROR_REASON_QUERY_PARAM } from '../../auth/constants';

const getHasOnboarding = ({ isSsoUser, hasNonSSOonboarding, hasCustomOnboarding }) => {
  if (isSsoUser) {
    return hasCustomOnboarding;
  }
  return hasNonSSOonboarding;
};

const getIsOnboardingNeeded = ({
  isSsoUser,
  hasOnboarded,
  hasNonSSOonboarding,
  hasCustomOnboarding,
}) => {
  if (isSsoUser && hasNonSSOonboarding) {
    const errorDescription = 'SSO user cannot have non-sso onboarding';
    clientLogger.error(errorDescription);
    navigateInternal(
      `/?${AUTH_ERROR_CODE_QUERY_PARAM}=403&${AUTH_ERROR_REASON_QUERY_PARAM}=${errorDescription}`
    );
  }

  if (hasOnboarded) {
    return false;
  }

  return getHasOnboarding({ isSsoUser, hasNonSSOonboarding, hasCustomOnboarding });
};

export default getIsOnboardingNeeded;
