import { getIsInternalUrl } from './getUrlType';

export const navigateExternal = (url, isBlank) => {
  if (getIsInternalUrl(url)) {
    throw new Error(
      `@people-analytix/util.navigateExternal - Cannot navigate to an address which is an internal url: ${url}`
    );
  }
  if (isBlank) {
    return window.open(url, '_blank');
  }
  window.location.href = url;
};
