import React from 'react';
import withStyles from '@mui/styles/withStyles';
import styles from './styles';
import useConfig from 'UI/customHooks/useConfig';

const UnsupportedBrowserPageComponent = ({ classes }) => {
  const { cdnUrl } = useConfig();

  return (
    <div className={classes.pageContent}>
      <div className={classes.wrapper}>
        <div className={classes.textWrapper}>
          <p className={classes.subtitle}>We are sorry, but your browser is not supported.</p>
          <p className={classes.info}>
            To ensure you have the best possible experience and the highest level of security, we
            are offering full support for <strong>Chrome</strong>,{' '}
            <strong>Internet Explorer 11</strong> and <strong>Edge 80 or newer.</strong>
          </p>
          <img
            className={classes.image}
            src={`${cdnUrl}/assets/unsupportedBrowser/unsupportedBrowser.png`}
            alt="Your browser is not supported"
          />
        </div>
      </div>
    </div>
  );
};

export const UnsupportedBrowserPage = withStyles(styles)(UnsupportedBrowserPageComponent);
